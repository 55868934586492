import './static/css/animate.min.css';
import './static/fonts/icomoon/style.css';
import './static/css/style.css';
import './src/styles/global.scss';

const scripts = [
  { defer: false, url: '/js/theme.js', isServerOnly: false }
];

const addJS = script => {
  return new Promise(resolve => {
    const s = document.createElement(`script`);

    s.type = `text/javascript`;
    s.src = script.url;
    if (script.defer) {
      s.setAttribute('defer', '');
    }
    document.getElementsByTagName('body')[0].appendChild(s);

    s.onload = () => {
      console.info(`Script ${script.url} loaded.`);
      resolve(true);
    };
  });
};

export { wrapRootElement } from './src/apollo/wrap-root-element';

export const onInitialClientRender = async () => {
  let index = 0;

  while (index < scripts.length) {
    const script = scripts[index];
    const { isServerOnly } = script;
    const isLocalhost = window.location.href.includes('localhost');

    index += 1;

    if (isLocalhost && isServerOnly) {
      return;
    }

    await addJS(script);
  }
};
